<template>
  <div class="grade">
    <amplify-connect :subscription="requisitionSubscription" :onSubscriptionMsg="onRequisitionUpdate">
      <template slot-scope="{ errors }">
        <div v-if="errors.length > 0">Error loading requisition</div>
      </template>
    </amplify-connect>
    <div v-if="requisition" class="container narrow-container has-background-white has-shadow has-margin-top">
      <section class="section has-small-padding-bottom">
        <h3 class="is-size-3 has-text-centered">Order Summary</h3>
        <p class="is-size-7 has-text-grey has-text-centered">Order Reference Number: {{ requisition.id }}</p>
        <div>
          <p class="is-overline">Service</p>
          <p>
            {{ requisition.service.title }}
            <br />
            <router-link class="is-size-7" :to="`/service/${requisition.service.slug || requisition.service.id}`"
              >View Service</router-link
            >
          </p>
        </div>
        <div v-if="requisition.amount && requisitionStatus === STATUS.FULFILLED && !requisition.subscription">
          <p class="is-overline">Order Amount</p>
          <p>
            ${{ (requisition.amount / 100).toFixed(2) }}
            <span v-if="requisition.test || isOwnService">(not charged - test)</span>
            <br />
            <a v-if="requisition.receiptUrl" :href="requisition.receiptUrl" target="_blank" class="is-size-7">
              View Invoice
              <i class="fas fa-external-link-alt is-primary-color"></i>
            </a>
          </p>
        </div>
        <div>
          <p class="is-overline">Order Date</p>
          <p>
            {{ new Date(requisition.createdAt).toLocaleDateString() }} at
            {{ new Date(requisition.createdAt).toLocaleTimeString() }}
          </p>
        </div>
        <a href="#" class="is-overline" v-on:click="showInputData = !showInputData">
          <span v-if="!showInputData">
            Show service input
            <i class="el-icon-arrow-right"></i>
          </span>
          <span v-if="showInputData">
            Hide service input
            <i class="el-icon-arrow-left"></i>
          </span>
        </a>
        <!-- using an internal element ui collapse utility function -->
        <el-collapse-transition>
          <div v-show="showInputData && requisition.input">
            <div v-for="input in requisition.input" :key="input.key">
              <p class="is-overline">{{ input.label || input.key }}</p>
              <div v-if="input.type === INPUT_TYPE.COLOR">
                <p class="is-size-6-7">
                  <span :style="{ color: input.value }">
                    <i class="fas fa-circle"></i>
                  </span>
                  {{ input.value }}
                </p>
              </div>
              <div v-else-if="input.type === INPUT_TYPE.FILE">
                <div v-if="getFileType(input.value) === 'audio'" class="has-margin-top">
                  <audio :src="input.value" controls></audio>
                </div>
                <div v-else-if="getFileType(input.value) === 'video'" class="has-text-centered has-margin-top">
                  <video
                    preload="true"
                    playsinline="true"
                    loop="true"
                    muted="muted"
                    controls
                    :src="input.value"
                  ></video>
                </div>
                <div v-else-if="getFileType(input.value) === 'image'" class="has-margin-top has-text-centered">
                  <el-image :src="input.value" fit="contain" style="width: 100%; height: 300px"></el-image>
                  <a :href="input.value" download target="_blank">
                    <el-button class="has-margin-top" type="primary">Download</el-button>
                  </a>
                </div>
                <div v-else>
                  <a download :href="input.value">{{ input.value }}</a>
                </div>
              </div>
              <div v-else>
                <p class="is-size-6-7">{{ valueDisplay(input.value) }}</p>
              </div>
            </div>
          </div>
        </el-collapse-transition>
        <!-- <div v-if="requisitionStatus === STATUS.FULFILLED">
          <p class="is-overline">Status</p>
          <p>Fulfilled</p>
        </div>-->
      </section>
      <section class="section has-small-padding-top">
        <h3 class="is-size-3 has-text-centered">Order Status</h3>
        <br />
        <el-steps align-center :active="activeStep" v-if="!errorSteps" finish-status="finish">
          <el-step v-for="step in steps" :key="step.title" :title="step.title" icon>
            <template v-slot:icon v-if="step.title === 'Processing' && activeStep === 1">
              <div class="processing-spin">&nbsp;</div>
            </template>
            <template v-slot:description class="wide-description">
              <!-- this is jank cause elementui is really inflexible -->
              <div class="wide-description">{{ step.description || '' }}</div>
            </template>
          </el-step>
        </el-steps>
        <el-steps align-center :active="1" v-if="errorSteps" process-status="error">
          <el-step v-for="step in errorSteps" :key="step.title" :title="step.title" finish-status="error">
            <template v-slot:description class="wide-description">
              <!-- this is jank cause elementui is really inflexible -->
              <div class="wide-description">{{ step.description || '' }}</div>
            </template>
          </el-step>
        </el-steps>
      </section>
      <section class="section has-no-padding-top has-small-padding-bottom">
        <div
          v-if="!requisition.output && !manuallyFulfilling && isSeller"
          class="has-text-centered has-margin-top"
          @click="
            requisition.output = []
            manuallyFulfilling = true
          "
        >
          <el-button type="primary">Manually fulfill order</el-button>
        </div>
        <div v-if="requisition.output || manuallyFulfilling">
          <h3 class="is-size-3 has-text-centered">Output</h3>
          <div class="divider"></div>
          <div v-if="!manuallyFulfilling">
            <div class="has-text-centered" v-if="isSeller">
              <el-button type="text" @click="manuallyFulfilling = true">Edit Output</el-button>
            </div>
            <div v-if="requisition.outputNote">
              <p class="has-text-grey is-size-6-7">Note: {{ requisition.outputNote }}</p>
            </div>
            <div v-if="requisition.output && !manuallyFulfilling">
              <div v-for="output in requisition.output" :key="output.key" class="has-small-padding-top">
                <p class="is-overline">{{ output.label || output.key }}</p>
                <div v-if="output.type === OUTPUT_TYPE.VIDEO">
                  <br />
                  <video
                    preload="true"
                    playsinline="true"
                    autoplay="true"
                    loop="true"
                    muted="muted"
                    controls
                    :src="output.value"
                  ></video>
                  <a :href="output.value" download target="_blank">
                    <el-button class="has-margin-top" type="primary">Download</el-button>
                  </a>
                </div>
                <div class="has-text-centered" v-else-if="output.type === OUTPUT_TYPE.IMAGE">
                  <el-image :src="output.value" fit="contain" style="width: 100%; height: 300px"></el-image>
                  <a :href="output.value" download target="_blank">
                    <el-button class="has-margin-top" type="primary">Download</el-button>
                  </a>
                </div>
                <div v-else-if="output.type === OUTPUT_TYPE.AUDIO" class="has-margin-top">
                  <audio :src="output.value" controls></audio>
                </div>
                <div v-else>
                  <p class="is-size-6-7">{{ output.value }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="has-text-centered has-margin-bottom">
              <el-button type="primary" @click="updateRequisitionOutput">Save Output</el-button>
              <el-button type="text" @click="cancelUpdateOutput">Cancel</el-button>
            </div>
            <requisition-fulfillment
              v-bind:outputs.sync="requisition.output"
              v-bind:note.sync="requisition.outputNote"
            ></requisition-fulfillment>
          </div>
        </div>
        <br />
      </section>
    </div>
    <br class="is-hidden-mobile" />
  </div>
</template>

<style scoped>
.wide-description {
  margin-left: -15% !important;
  margin-right: -15% !important;
}

.processing-spin:before {
  border: 4px solid #f8f8f8; /* Light grey */
  border-top: 4px solid rgb(92, 38, 199); /* Blue */
  border-radius: 50%;
  position: absolute;
  width: 30px;
  height: 30px;
  left: calc(calc(20px - 30px) / 2);
  top: calc(calc(20px - 30px) / 2);
  background: white;
  animation: spin 2s cubic-bezier(0.42, 0.1, 0.58, 0.9) infinite;
  content: '';
  z-index: -1;
}

.processing-spin {
  z-index: 10001;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

@keyframes spin-backwards {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import { STATUS, INPUT_TYPE, OUTPUT_TYPE } from '@/graph-constants'
// import { onUpdateRequisition } from '@/graphql/subscriptions';
import { updateRequisition } from '@/graphql/mutations'
import { stringIsJson, getFileType } from '@/helpers/utils'
import RequisitionFulfillment from '@/components/RequisitionFulfillment.vue'

const getRequisition = `query GetRequisition($id: ID!) {
  getRequisition(id: $id) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      archived
      owner
      draft
    }
    amount
    input {
      key
      value
      label
      type
    }
    createdAt
    status {
      value
      date
      description
    }
    output {
      key
      value
      label
      type
    }
    outputNote
    canceled
    refunded
    archived
    test
    owner
    vendor
    api
    chargeId
    receiptUrl
    paymentType
    subscription {
      id
      stripeId
      owner
      plan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      createdAt
    }
  }
}
`

const onUpdateRequisition = `subscription OnUpdateRequisition($owner: String) {
  onUpdateRequisition(owner: $owner) {
    id
    amount
    outputNote
    test
    receiptUrl
    paymentType
    output {
      key
      value
      label
      type
    }
    subscription {
      id
      stripeId
      owner
      plan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
    }
    status {
      value
      date
      description
    }
  }
}
`

export default {
  name: 'Requisition',
  components: {
    RequisitionFulfillment,
  },
  data() {
    return {
      STATUS,
      INPUT_TYPE,
      OUTPUT_TYPE,
      id: this.$route.params.id,
      requisition: null,
      isOwnService: false,
      isSeller: false,
      manuallyFulfilling: false,
      showInputData: false,
    }
  },
  async created() {
    const res = await API.graphql(graphqlOperation(getRequisition, { id: this.id }))
    this.requisition = res.data.getRequisition
    this.isOwnService = this.requisition.service.owner === this.requisition.owner
    this.isSeller = this.requisition.service.owner === this.$store.state.user.username
  },
  computed: {
    getRequisitionQuery() {
      return graphqlOperation(getRequisition, { id: this.id })
    },
    requisitionSubscription() {
      return graphqlOperation(onUpdateRequisition, { owner: this.$store.state.user.username, id: this.id })
    },
    requisitionStatus() {
      if (this.requisition && Array.isArray(this.requisition.status)) {
        return this.requisition.status[this.requisition.status.length - 1].value
      }
      return null
    },
    processingStatus() {
      const defaultDescription = 'Your order is processing...'
      let progressDescription
      if (this.requisition) {
        const processingStatuses = this.requisition.status.filter((status) => status.value === STATUS.PROCESSING)
        if (processingStatuses.length > 0) {
          progressDescription = processingStatuses[processingStatuses.length - 1].description
        }
      }
      return progressDescription || defaultDescription
    },
    activeStep() {
      switch (this.requisitionStatus) {
        case STATUS.FULFILLED:
          return 3
        default:
          return 1
      }
    },
    steps() {
      return [
        {
          title: 'Placed',
          description: 'Your order has been placed',
        },
        {
          title: 'Processing',
          description: this.activeStep === 1 ? this.processingStatus : 'Your order has been processed',
        },
        {
          title: 'Fulfilled',
          description: this.activeStep === 1 ? 'Awaiting fulfillment' : 'Your order is complete!',
        },
      ]
    },
    errorSteps() {
      if (![STATUS.REJECTED, STATUS.NOT_RECEIVED, STATUS.FAILED].includes(this.requisitionStatus)) {
        return false
      }
      let errorTitle = 'Oh no!'
      let errorDescription = 'Something went wrong'
      if (this.requisitionStatus === STATUS.REJECTED) {
        errorTitle = 'Order Rejected'
        errorDescription = 'Your order was rejected by the seller'
        if (this.requisition.status[this.requisition.status.length - 1].description) {
          errorDescription = this.requisition.status[this.requisition.status.length - 1].description
        }
      }
      if (this.requisitionStatus === STATUS.NOT_RECEIVED) {
        errorTitle = 'Order Not Received'
        errorDescription = 'Your order could not be placed with the seller'
        if (this.requisition.status[this.requisition.status.length - 1].description) {
          errorDescription = this.requisition.status[this.requisition.status.length - 1].description
        }
      }
      if (this.requisitionStatus === STATUS.FAILED) {
        errorTitle = 'Order could not be filled'
        errorDescription = 'Your order could not be fulfilled.'
        if (this.requisition.status[this.requisition.status.length - 1].description) {
          errorDescription = this.requisition.status[this.requisition.status.length - 1].description
        }
      }
      return [
        {
          title: 'Placed',
          description: 'Your order has been placed',
        },
        {
          title: errorTitle,
          description: errorDescription,
        },
      ]
    },
  },
  methods: {
    getFileType(url) {
      return getFileType(url)
    },
    valueDisplay(value) {
      if (stringIsJson(value)) {
        const json = JSON.parse(value)
        if (Array.isArray(json)) {
          return json.join(', ')
        }
        return value
      }
      return value
    },
    onRequisitionUpdate(prevData, newData) {
      if (newData.onUpdateRequisition.id !== this.requisition.id) {
        return this.requisition
      }
      // eslint-disable-next-line
      const fieldsToCheck = [
        'status',
        'outputNote',
        'output',
        'amount',
        'test',
        'receiptUrl',
        'subscription',
        'paymentType',
      ]
      fieldsToCheck.forEach((field) => {
        if (this.requisition && newData.onUpdateRequisition[field]) {
          this.requisition[field] = newData.onUpdateRequisition[field]
        }
      })
      return this.requisition
    },
    cancelUpdateOutput() {
      window.location.reload()
    },
    async updateRequisitionOutput() {
      this.manuallyFulfilling = false
      const status = this.requisition.status.concat({
        date: +new Date(),
        description: 'Manual entry',
        value: STATUS.FULFILLED,
      })
      const outputs = this.requisition.output.map((output) => ({
        type: output.type,
        label: output.label,
        key: output.key,
        value: output.value,
      }))
      const requisitionObject = {
        id: this.requisition.id,
        status,
        outputNote: this.requisition.outputNote || null,
        output: outputs,
      }
      const updatedRequisition = await API.graphql(
        graphqlOperation(updateRequisition, {
          input: requisitionObject,
        })
      )
      this.requisition = updatedRequisition.data.updateRequisition
      // this.$router.push(`/order/${this.requisition.id}`)
    },
  },
}
</script>
