<template>
<div>
  <el-form label-width="120px">
    <el-form-item label="Note">
      <el-input v-bind:value="note" v-on:input="$emit('update:note', $event)"
        placeholder="Note to the buyer (optional)">
      </el-input>
    </el-form-item>
  </el-form>
  <div v-for="output in outputs" :key="output.localId">
    <el-form label-width="120px">
      <el-form-item label="Item Type">
        <el-radio-group v-model="output.type" v-on:change="emitUpdate" size="medium">
          <el-radio-button v-for="type in outputTypes" :label="type.key" :key="type.key">
            {{ type.label }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Key">
        <el-input v-model="output.key" v-on:input="emitUpdate" placeholder="Unique identifier (for bookkeeping)">
        </el-input>
      </el-form-item>
      <el-form-item label="Label">
        <el-input v-model="output.label" v-on:input="emitUpdate" placeholder="Customer facing label">
        </el-input>
      </el-form-item>
      <el-form-item label="Text" v-if="output.type === OUTPUT_TYPE.TEXT">
        <el-input v-model="output.value" v-on:input="emitUpdate" placeholder="Customer facing label">
        </el-input>
      </el-form-item>
      <el-form-item label="URL" class="has-no-margin-bottom" v-if="output.type !== OUTPUT_TYPE.TEXT">
        <el-input v-if="!metaOutput[output.localId].uploadFile"
          v-model="output.value"
          placeholder="Output value">
        </el-input>
        <el-button type="text" size="small" @click="metaOutput[output.localId].uploadFile = true"
          v-if="!metaOutput[output.localId].uploadFile && user">
          Or Upload File
        </el-button>
        <el-button type="text" size="small"
          v-if="!metaOutput[output.localId].uploadFile && !user">
          Or Upload File
        </el-button>
        <file-picker v-if="metaOutput[output.localId].uploadFile" storage-level="public"
          accept="*/*" :autoInvoke="true"
          v-on:fileUpload="output.value = getPublicUrl($event)"
        ></file-picker>
        <el-button type="text" size="small" @click="metaOutput[output.localId].uploadFile = false"
          v-if="metaOutput[output.localId].uploadFile">
          Or Input URL
        </el-button>
      </el-form-item>
    </el-form>
    <el-button type="text" @click="removeOutput(output.localId)">Remove Output Above</el-button>
  </div>
  <el-button type="primary" @click="addOutput">Add Output Below</el-button>
</div>
</template>

<script>
import { OUTPUT_TYPE } from '@/graph-constants';
import { publicUrl } from '@/api/storage';
import FilePicker from '@/components/FilePicker.vue';

export default {
  name: 'RequisitionFulfillment',
  components: {
    FilePicker,
  },
  props: {
    outputs: Array,
    note: String,
  },
  data() {
    return {
      OUTPUT_TYPE,
      outputTypes: [
        { key: OUTPUT_TYPE.TEXT, label: 'Text' },
        { key: OUTPUT_TYPE.IMAGE, label: 'Image' },
        { key: OUTPUT_TYPE.VIDEO, label: 'Video' },
        { key: OUTPUT_TYPE.LINK, label: 'Link' },
        { key: OUTPUT_TYPE.AUDIO, label: 'Audio' },
      ],
      metaOutput: null, // for bookkeeping
      user: this.$store.state.user,
      outputId: 0,
    }
  },
  created() {
    this.outputId = this.outputs.length
    this.outputs.forEach((d, i) => {
      d.localId = i
    })
    this.metaOutput = (this.outputs || []).reduce((acc, output) => {
      acc[output.localId] = {}
      acc[output.localId].uploadFile = false
      return acc
    }, {})
  },
  methods: {
    addOutput() {
      this.metaOutput[this.outputId] = { uploadFile: false }
      const outputs = this.outputs.concat({
        type: OUTPUT_TYPE.TEXT,
        value: '',
        key: `output_${this.outputId}`,
        label: `Output ${this.outputId}`,
        localId: this.outputId
      })
      this.outputId += 1
      this.$emit('update:outputs', outputs)
    },
    removeOutput(localId) {
      const outputs = this.outputs.filter(output => output.localId !== +localId)
      this.$emit('update:outputs', outputs)
    },
    emitUpdate() {
      this.$emit('update:outputs', this.outputs)
    },
    getPublicUrl(key) {
      return `${publicUrl}/${key}`
    },
  },

}
</script>
